@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'DIN Next W01 Light';
  src: url('../fonts/DINNextLTPro-Light.ttf');
}

@font-face {
  font-family: 'DIN Next W01 Light Italic';
  src: url('../fonts/DINNextLTPro-LightItalic.ttf');
}

@font-face {
  font-family: 'DIN Next W01 Regular';
  src: url('../fonts/DINNextLTPro-Regular.ttf');
}

@font-face {
  font-family: 'DIN Next W01 Italic';
  src: url('../fonts/DINNextLTPro-Italic.ttf');
}

@font-face {
  font-family: 'DIN Next W01 Medium';
  src: url('../fonts/DINNextLTPro-Medium.ttf');
}

@font-face {
  font-family: 'DIN Next W01 Bold';
  src: url('../fonts/DINNextLTPro-Bold.ttf');
}

@font-face {
  font-family: 'Open Sans Light';
  src: url('../fonts/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'Open Sans Light Italic';
  src: url('../fonts/OpenSans-LightItalic.ttf');
}

@font-face {
  font-family: 'Open Sans Regular';
  src: url('../fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans Italic';
  src: url('../fonts/OpenSans-Italic.ttf');
}

@font-face {
  font-family: 'Open Sans Medium';
  src: url('../fonts/OpenSans-Medium.ttf');
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('../fonts/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-Regular.woff2') format('woff2'),
    url('../fonts/telegraph/PPTelegraf-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-Bold.woff2') format('woff2'),
    url('../fonts/telegraph/PPTelegraf-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-RegularSlanted.woff2') format('woff2'),
    url('../fonts/telegraph/PPTelegraf-RegularSlanted.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-BoldSlanted.woff2') format('woff2'),
    url('../fonts/telegraph/PPTelegraf-BoldSlanted.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-Medium.woff2') format('woff2'),
    url('../fonts/telegraph/PPTelegraf-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-MediumSlanted.woff2') format('woff2'),
    url('../fonts/telegraph/PPTelegraf-MediumSlanted.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-Light.woff2') format('woff2'),
    url('../fonts/telegraph/PPTelegraf-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-LightSlanted.woff2') format('woff2'),
    url('../fonts/telegraph/PPTelegraf-LightSlanted.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-Black.woff2') format('woff2'),
    url('../fonts/telegraph/PPTelegraf-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-BlackSlanted.woff2') format('woff2'),
    url('../fonts/telegraph/PPTelegraf-BlackSlanted.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-Semibold.woff2') format('woff2'),
    url('../fonts/telegraph/PPTelegraf-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-SemiboldSlanted.woff2')
      format('woff2'),
    url('../fonts/telegraph/PPTelegraf-SemiboldSlanted.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-Ultrabold.woff2') format('woff2'),
    url('../fonts/telegraph/PPTelegraf-Ultrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Telegraf';
  src: url('../fonts/telegraph/PPTelegraf-UltraboldSlanted.woff2')
      format('woff2'),
    url('../fonts/telegraph/PPTelegraf-UltraboldSlanted.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

html,
body {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
  font-family: 'Inter', 'DIN Next W01 Regular', sans-serif;
}

.contentful-markdown img {
  max-width: 100%;
  object-fit: contain;
}

.contentful-markdown p,
.contentful-markdown li {
  font-size: 1.25rem;
  font-family: 'DIN Next W01 Light', sans-serif;
}

@media (min-width: 768px) {
  .contentful-markdown p,
  .contentful-markdown li {
    font-size: 1.4rem;
  }
}

.contentful-markdown p {
  line-height: 1.5;
  margin: 0 1rem 2rem 0;
}

.contentful-markdown strong {
  font-size: inherit;
}

.contentful-markdown h3 {
  font-family: 'DIN Next W01 Medium', sans-serif;
  font-size: 1.25rem;
  margin: 2rem 2rem 2rem 0;
}

@media (min-width: 768px) {
  .contentful-markdown h3 {
    font-size: 2rem;
  }
}

.contentful-markdown ol,
.contentful-markdown ul {
  padding-left: 0;
  margin: 0 0 2rem 0;
}

.contentful-markdown ol li {
  list-style: decimal inside;
}

.contentful-markdown ul li {
  list-style: circle;
  margin-bottom: 0.75rem;
}

.contentful-markdown iframe {
  width: 100%;
  max-width: 800px;
}

.responsive-iframe-wrapper {
  @apply relative h-0 overflow-hidden;
  padding-bottom: 56.25%; /* 16:9 Ratio */
}

.responsive-iframe-wrapper iframe {
  @apply border-0 absolute top-0 left-0 w-full h-full;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}

.slick-next {
  right: 3% !important;
  z-index: 1;
}

.custom-arrow.slick-prev:before {
  display: none;
}

.custom-arrow.slick-next:before {
  display: none;
}

.slick-dots {
  bottom: 0;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}

.slick-dots li {
  margin: 0 0.25rem;
}

.slick-dots li button {
  display: block;
  width: 5px;
  height: 5px;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: gainsboro;
  text-indent: -9999px;
}

.slick-dots li.slick-active button {
  width: 8px;
  height: 8px;
  background-color: white;
}
